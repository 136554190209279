<template>
  <div class="main_business">
    <headerNav pathname="主营业务" />
    <text-common
      title="主营业务"
      :img="img"
      :setClass="true"
    />
    <div class="advantage">
      <div class="titleDiv">
        <p>优势路线</p>
        <p>Advantageous route</p>
        <div class="Underline"></div>
      </div>
      <div class="content">
        <div class="card">
          <img src="../assets/img/km.png">
          <p><b>长三角至昆明（金马村） 特快快速班列</b></p>
        </div>
        <div class="card">
          <img src="../assets/img/gy.png">
          <p><b>长三角至贵阳（改貌） 特快快速班列</b></p>
        </div>
        <div class="card">
          <img src="../assets/img/wlmq.png">
          <p><b>长三角至乌鲁木齐（乌东） 特快快速班列</b></p>
        </div>
      </div>
    </div>
    <div class="center_content">
      <div class="titleDiv">
        <p>产品类型</p>
        <p>product type</p>
        <div class="Underline"></div>
      </div>
      <!-- 去掉G T 产品 -->
      <!-- 
      <div class="precision_high_speed_bg">
        <img
          src="../assets/main_business/G_img.jpg"
          alt=""
        />
        <div class="precision_high_speed">
          <div class="title">精准高快G</div>
          <div class="item">
            “精准高快G”是为了满足客户对货物高时效要求推出的产品，它以“高效、安全、专业、全方位跟踪、优质服务”作为服务基础，以“不负重托”为整个产品乃至整个公司的品牌代言。以此为基础，我司将打造成为物流企业中的佼佼者。
          </div>
        </div>
      </div>

      <div class="precision_express_bg">
        <div class="precision_express">
          <div class="title">精准特快T</div>
          <div class="item">
            “精准特快T”是驮龙物流开业即推出的为企业及零散客户提供高端快运服务的标准快运产品，运输途中无中转，GPS全程定位，专人服务，安全快捷，主要城市之间的运输时间为2-3天，为客户提供经济实用的标准化运输服务，安全、准确、方便。
          </div>
        </div>
        <img
          src="../assets/main_business/T_img.jpg"
          alt=""
        />
      </div>
-->
      <div class="special_express_bg">
        <img
          src="../assets/main_business/K_img.jpg"
          alt=""
        />
        <div
          class="precision_high_speed"
          style="padding-top: 58px"
        >
          <div class="title">特惠快运K</div>
          <div class="item">
            “特惠快运K”作为我司推出的性价比最高的产品，采用标准定价与标准操作流程，为客户提供安全可靠、服务专业、高性价比的服务体验。安全经济的运输方式，让您发货放心，收货安心。优质运输产品，稳定的运力资源，定班次每日准时发车、准点到达，运行时效稳定，一般3-4天可达。
          </div>
        </div>
      </div>

      <div
        class="precision_express_bg"
        style="padding: 70px 0 0 0"
      >
        <div class="precision_express">
          <div class="title">普惠班列P</div>
          <div class="item">“普惠班列P”作为我司推出的最经济实惠的产品，为客户提供优惠的价格、优质的服务和精准的时效，保障货物准时送达，具有价格与安全双重优势，大宗货物运输产品，价格经济实惠，标准操作，便捷服务，运行时效稳定，一般周内可达，安全系数较高
            。
          </div>
        </div>
        <img
          src="../assets/main_business/P_img.jpg"
          alt=""
        />
      </div>
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../assets/main_business/main_business.png"),
    }
  }
}
</script>

<style lang="less" scoped>
.main_business {
  .titleDiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    p:first-child {
      // font-size: 20px;
      // color: red;
      color: #734f21;
      font-size: 24px;
      font-weight: 500;
      font-family: PingFangSC-Medium;
    }
    p:nth-child(2) {
      // font-size: 12px;
      font-size: 14px;
      // margin-bottom: 26px;
      font-family: PingFangSC-Medium;
    }
    .Underline {
      width: 28px;
      height: 5px;
      background: #734f21;
      margin: 5px auto;
    }
  }

  .advantage {
    width: 100%;
    height: 500px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-top: 70px;
    box-sizing: border-box;

    .content {
      margin-top: 30px;
      width: 1280px;
      display: flex;
      justify-content: space-around;
      .card {
        width: 400px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 400px;
        }
        p {
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }
  .center_content {
    width: 100%;
    padding: 103px 0 114px 0;
    box-sizing: border-box;
    img {
      width: 520px;
      //   height: 262px;
    }
    background: #fff;
    .precision_high_speed_bg {
      margin-top: 50px;
    }
    .precision_high_speed_bg,
    .precision_express_bg,
    .special_express_bg,
    .pratt_whitney_train_bg {
      display: flex;
      justify-content: center;
    }
    .precision_high_speed {
      width: 520px;
      height: 262px;
      padding: 74px 0 0 0;
      box-sizing: border-box;
      margin-left: 30px;
      //   background: #ffffff;
    }
    .title {
      color: #734f21;
      font-size: 20px;
      font-weight: 550;
    }
    .item {
      font-size: 14px;
      opacity: 0.85;
      line-height: 30px;
      margin-top: 10px;
    }

    .precision_express_bg {
      padding: 70px 0;
      box-sizing: border-box;
      .precision_express {
        width: 520px;
        height: 262px;
        padding: 56px 0 0 0;
        box-sizing: border-box;
        margin-right: 30px;
      }
    }
  }
}
</style>